<template>
    <div class="design-ideas-page">
        <DisplayBanner img="designDisplay.png">
            <div class="wrapper">
                <Hexagon
                    t1="Home Decor"
                    t2="Ideas"
                    t3="But so much more than that!"
                />
            </div>
        </DisplayBanner>

        <div class="content-container">
            <div class="di-description">
                <HeadAndSubTexts
                    subHeading="Wise people say inspiration can often be short-lived. But we are here to help you feel inspired, excited and eager daily with some gorgeous design concepts. So no matter the kind of day you are having, our editors will ensure that your creative mind gets the fuel it needs regularly."
                />
            </div>
            <div class="styles-header">
                <HeadAndSubTexts heading="Design Ideas by Style" />
            </div>

            <div class="styles-container">
                <div
                    class="cards"
                    v-for="style in designIdeasList.payload.styles"
                    :key="style.id"
                >
                    <router-link
                        :to="{
                            name: 'ThemeDesignIdeasPage',
                            params: { id: style.id },
                        }"
                    >
                        <Cards
                            :cardname="style.theme"
                            :img="style.image.p_image"
                        />
                    </router-link>
                </div>
            </div>

            <StyleBox
                class="style-quiz-banner"
                :leftBanner="true"
                img="vintage-4188774_1920.png"
                heading="Unsure About Your Decor Style?"
            />
            <div class="styles-header">
                <HeadAndSubTexts heading="Design Ideas by Space" />
            </div>
            <div class="spaces-container">
                <div
                    class="cards"
                    v-for="space in designIdeasList.payload.spaces"
                    :key="space.id"
                >
                    <router-link
                        :to="{
                            name: 'RoomDesignIdeasPage',
                            params: { id: space.id },
                        }"
                    >
                        <Cards
                            :cardname="space.room"
                            :img="space.image.p_image"
                        />
                    </router-link>
                </div>
            </div>
        </div>
        <template v-if="loaderShow">
            <div class="wait">
                <Loader :loaderType="loaderType" />
            </div>
        </template>
        <Footer class="footer" />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import RegisterStoreModule from 'mixins/RegisterStoreModule'
import DesignIdeasListPageModule from 'store/modules/DesignIdeasListPage'

import DisplayBanner from 'componentsv2/DisplayBanner'
import Hexagon from 'componentsv2/Hexagon'
import HeadAndSubTexts from 'componentsv2/HeadAndSubTexts'
import StyleBox from 'componentsv2/StyleBox'
import Cards from 'componentsv2/Cards'
import Loader from 'componentsv2/Loader'
import Footer from 'componentsv2/Footer'
import loaderHandler from 'mixins/loader'

export default {
    name: 'DesignIdeasListPage',
    components: {
        DisplayBanner,
        Hexagon,
        HeadAndSubTexts,
        StyleBox,
        Cards,
        Loader,
        Footer,
    },
    props: {},
    mixins: [RegisterStoreModule, loaderHandler],
    computed: {
        ...mapState({
            designIdeasList: state => state.DesignIdeasListPage.designIdeasList,
        }),
    },
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'SILOHO',
        // all titles will be injected into this template
        titleTemplate: '%s | Design Ideas',
    },
    created() {
        document.querySelector(
            "meta[property='og:title']",
        ).content = `Home Decor Ideas, But so much more than that`

        document.querySelector(
            "meta[property='og:description']",
        ).content = `Wise people say inspiration can often be short-lived. But we are here to help you feel inspired, excited and eager daily with some gorgeous design concepts. So no matter the kind of day you are having, our editors will ensure that your creative mind gets the fuel it needs regularly.`

        document.querySelector('body').style.overflow = 'hidden'
        this.registerStoreModule(
            'DesignIdeasListPage',
            DesignIdeasListPageModule,
        )
        this.fetchDesignIdeasList(10)
            .then(response => {
                if (response.responseCode == 200) {
                    setTimeout(() => {
                        document.querySelector('body').style.overflow = 'auto'
                    }, 1000)
                }
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
            .catch(err => {
                setTimeout(() => {
                    this.loaderShow = false
                }, 1000)
            })
    },
    destroyed() {
        this.$store.unregisterModule('DesignIdeasListPage')
    },
    methods: {
        ...mapActions({
            fetchDesignIdeasList: 'fetchDesignIdeasList',
        }),
    },
}
</script>

<style lang="scss" scoped>
@import './DesignIdeasListPage.scss';
</style>
