<template>
    <div class="hexagon"
        :style="{
            height: 'auto',
            padding: '0 5px'
        }"
    >
        <hr>
        <h1 class="t1"
            :style="{
                padding: hexaPadd,
            }"
        >{{t1}}</h1>
        <h1 class="t2">{{t2}}</h1>
        <h1 class="t3">{{t3}}</h1>
        <hr>
    </div>
</template>

<script>
export default {
    name: 'Hexagon',
    props: {
        t1: {
            type: String
        },
        t2: {
            type: String
        },
        t3: {
            type: String
        }
    },
    computed:{
        hexaPadd(){
            return this.hexaMethod('10px 0 10px 0','0')
        }
    },
    methods:{
        hexaMethod(value1, value2){
            if(this.t1!=undefined && this.t2==undefined && this.t3==undefined){
                return value1
            }else{
                return value2
            }
        }
    },
}
</script>

<style lang="scss" scoped>
@import './Hexagon.scss';
</style>