import { getDesignIdeas } from 'api/designIdeasApi'


const DesignIdeasListPage = {
    state: () => ({
        designIdeasList: []
    }),

    mutations: {
        setDesignIdeasList(state, payload){
            state.designIdeasList = payload
        },
    },

    getters: {},

    actions: {
        fetchDesignIdeasList({commit}){
            return getDesignIdeas()
                .then(response => {
                    if (response.status === 404) {
                        console.log('fetching failed')
                    } else {
                        commit('setDesignIdeasList', response)
                        return response
                    }
                })
        },
    }
}

export default DesignIdeasListPage;